<partial-header titulo="Sugestão de Compra"></partial-header>
<div class="page"> 
    <div class="container">
        <div class="dm-card dm-card-info">
            <div class="dm-card-content">
                <span class="titulo" [style.color]="usuario.cor_primaria">PASSEIOS SUGERIDOS</span>
                <div class="bloco">
                    <div class="icone">
                        <i [style.color]="usuario.cor_secundaria" class="fas fa-shopping-bag"></i>
                    </div> 
                    <div class="texto">
                        <div class="texto-content">
                            <span [style.color]="usuario.cor_primaria">Temos <strong [style.color]="usuario.cor_secundaria">{{servicos.length}}</strong> serviços</span><br>
                            <span [style.color]="usuario.cor_primaria">para sugerir para você :)</span>
                        </div>    
                    </div>     
                </div>    
            </div>    
        </div> 
        <div class="dm-card">
            <div class="dm-card-content">
                <div class="item" *ngFor="let s of servicos">
                    <div class="item-title" (click)="_show(s)">
                        <div class="circle" [style.background]="usuario.cor_primaria">
                            <span>{{s.servico | nomeLabel}}</span>
                        </div>
                        <div class="content">    
                            <span>{{s.servico}}</span>
                        </div> 
                    </div> 
                    <div class="item-content" *ngIf="s.show">
                        <div class="datas">
                            <div class="data-item" *ngFor="let d of s.disponibilidadeDiaria">
                                <div class="icone">
                                    <i [style.color]="usuario.cor_secundaria" class="fas fa-calendar-alt"></i>
                                </div> 
                                <div class="texto">
                                    <span class="dia">{{d.dia}}</span>
                                    <span class="valor">{{d.tarifa | currency: " R$ "}}</span>
                                </div>   
                            </div>    
                        </div>    
                    </div>      
                </div>    
            </div>    
        </div>   
    </div>      
</div> 