<div class="dm-modal">
      <div class="dm-modal-titulo">
          <strong>Meus Dados</strong>
          <div class="dm-modal-close" (click)="_close()">
              <span>x</span> 
          </div>      
      </div>
      <div class="dm-modal-content">
            <div class="item"> 
                  <span class="label">NOME</span>
                  <span class="nome">{{usuario.nomeUsuario}}</span>  
            </div>
            <div class="item item-button"> 
                  <button class="btn-custom" [style.background]="usuario.cor_secundaria" (click)="_sair()">
                        Sair do app
                  </button>      
            </div>      
      </div>     
</div>     
  