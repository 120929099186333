<div class="modulo-banner">
    <div class="content">
        <div class="content-body">
            <span class="titulo" #tituloMain></span> 
            <a routerLink="contato" title="Entre em contato - Digital Movement" class="btn-two radius animated-four fadeIn">
                Entre em contato 
            </a>   
        </div>      
    </div>
    <figure>
        <img [src]="imagem" [alt]="data.titulo" class="img-fluid" />
    </figure>    
</div>    
