import { ModuloService } from './../services/modulo.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { DataService } from './../services/data.service';
import { AppService } from './../services/app.service';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';

@Injectable({
    providedIn: 'root'
  })
export class GuestResolver implements Resolve<any> {
    
    private title;
    public data = null;

    constructor(
      private DataService:DataService,
      private modulo: ModuloService,
      private app: AppService,
      private api: ApiService,
      private router: Router
    ){

      this.onData();

    }
    /**
     * 
     * Ouvintes
     * 
     */
    onData(){
              
        this.modulo.getModulosEmitter.subscribe(d =>{
            this.modulo.updateModulosEmitter.emit(this.data);
        });

    }  
    /**
     * 
     * Resolve
     * 
     * @param route 
     * @param state 
     * 
     */
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        ):Observable<any>|Promise<any>|any {
           
            let modulos      = this.DataService.getModulos();
            let configuracao = this.DataService.getConfiguracao();
            let usuario      = this.DataService.getUsuario();
            
            if(usuario == null){
                this.router.navigateByUrl("/login");
            }

    }

 }
