import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  public usuario = null;

  constructor(
    public dialog: MatDialogRef<ModalConfirmComponent>, 
    @Inject(MAT_DIALOG_DATA) public data,
    private _data: DataService
  ){ }

  /**
   * 
   * Delete item
   * 
   * 
   */
  delete(){
    
    if(typeof(this.data.fnCallback) != "undefined"){
      this.data.fnCallback(false,this.data.self);
    }else{
      this.data.self.delete(this.data);
    }  
    this.close();
    
  }
  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    this.usuario = this._data.getUsuario();
  }

}
