<partial-header titulo="Contato"></partial-header>
<div class="page"> 
    <div class="container">
        <div class="content-form">
            <form class="form" (submit)="enviar()">
                <div class="form-group">
                    <input [style.color]="" type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu e-mail" />
                </div> 
                <div class="form-group">
                    <input type="number" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Informe seu e-mail"  />
                </div> 
                <div class="form-group">
                    <textarea [(ngModel)]="dataItem.mensagem" name="mensagem" placeholder="Escreva uma mensagem"></textarea>
                    <button type="submit" [style.background]="cor_primaria">  
                        <i class="fas fa-paper-plane" [style.color]="cor_secundaria"></i>
                    </button>  
                </div>       
            </form>    
        </div>    
    </div>      
</div> 