import { Injectable, EventEmitter } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { DataService } from './data.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalInfo } from '../components/modal/modal-info/modal-info.component';
import { ModalConfirmComponent } from '../components/modal/modal-confirm/modal-confirm.component';

declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class AppService {

  public setStatusServidorEmitter = new EventEmitter();
  public emitChangePage           = new EventEmitter();
  public setCategoriaMenu         = new EventEmitter();
  public emitChangeUsuario        = new EventEmitter();
  public title:Title;
  public meta:Meta;

  constructor(
    title: Title,
    meta: Meta,
    private dialog: MatDialog, 
    private data: DataService
  ) {
    this.title = title;
    this.meta  = meta;
  }
  
  /**
   * 
   * Formata as mensagens de erro 
   * 
   * @param err 
   * @param paxs 
   * 
   */
  getErrors(err,paxs=null){
    
    let messages = ""
    
    if(typeof(err) == "string"){
      messages = err
    }else{
      if(err.length > 1){
           
        for(let key in err) {
          if(paxs){
            let i = parseInt(key)+1 
            messages += "<h6>Passageiro: "+i+"<h6>"
          }
          for(let k in err[key]){
            messages += err[key][k]+"<br>"
          }
                   
        }
      }else{
        for(let key in err[0]) {
          messages += err[0][key]+"<br>"
        }
     }  
    
    }
    
    return messages
    
  }
  /**
   * 
   * Altera os dados das metas tags 
   * 
   * @param title 
   * @param description 
   * 
   */
  setMetaDados(title=null,description=null,keywords=null){
    
    //let nome   = AppSettings.NAME
    let nome   = "";
    let config = this.data.getConfiguracao();  
    nome       = config.meta_title; 
    
    if(title != null){
      title = title; 
      this.title.setTitle(title);
    }else{
      this.title.setTitle(nome);
    }
    if(description != null){
      this.meta.updateTag({
        name: "description",
        content: description
      })
    }
    if(keywords != null){
      this.meta.updateTag({
        name: "keywords",
        content: keywords
      })
    }
    
  }
  /**
   * 
   * Insere as meta tags iniciais 
   * 
   * @param title 
   * @param description 
   * 
   */
  setMetaDadosInit(configuracao){

    this.title.setTitle(configuracao.meta_title);
    this.meta.updateTag({
      name: "description",
      content: configuracao.meta_description
    });

  }
  /**
   * 
   * Envia para a section
   * 
   * @param id 
   * 
   */
  toSection(id){
   
    var top = $(id).offset().top;
       
    $('html, body').stop().animate({ 
      scrollTop: top-100
    }, 500);
   

  }
  /**
   * 
   * Envia para o topo
   * 
   * 
   */
  toTop(){ 
         
    $('html, body').stop().animate({ 
      scrollTop: 0
    }, 500);
   

  }
  /**
   * 
   * Formata as mensagens de erro 
   * 
   * @param err 
   * @param paxs 
   * 
   */
  formarErrorsValidation(err,paxs=null){
    
    let messages = ""
    
    if(typeof(err) == "string"){
      messages = err
    }else{
      if(err.length > 1){
           
        for(let key in err) {
          if(paxs){
            let i = parseInt(key)+1 
            messages += "<h6>Passageiro: "+i+"<h6>"
          }
          for(let k in err[key]){
            messages += err[key][k]+"<br>"
          }
                   
        }
      }else{
        for(let key in err[0]) {
          messages += err[0][key]+"<br>"
        }
     }  
    
    }
    
    return messages
    
  }
  /**
   * 
   * Formata o erro da API
   * 
   */
  formatError(response){

    let error = {
      status: null,
      statusText: null,
      message: null
    }

    let body = typeof(response._body) == "string" ? JSON.parse(response._body) : response._body;
    
    if(response.statusText){
      error.statusText = response.statusText;
    }
    if(response.status){
      error.status = response.status;
    }
    if(error.status == 405){
      error.message = "Método não permitido.";
    }
    if(body.errors && error.message == null){
      
      if(error.status == 422){

        error.message = this.formarErrorsValidation([body.errors]);

      }else if(body.message){
        error.message = body.message;
      }

    }else{
      
      if(typeof(body.message) != "undefined"){
       error.message = body.message;
      }else if(typeof(body.exception) != "undefined"){
        error.message = body.exception;
      }else{
        error.message = response.data;
      }

    }

    return error;


  }
  /**
   * 
   * Formata a data da atividade
   * 
   * 
   */
  formatDateAtividade(date:string):string{

    let d = date.split("/");

    return d[2]+"-"+d[1]+"-"+d[0];
    
  }
  /**
   * 
   * Formata a tarifa da atividade
   * 
   * 
   */
  formatTarifaAtividade(tarifa:string):number{

    tarifa = tarifa.replace(new RegExp(/[.]/g),"");
    tarifa = tarifa.replace(new RegExp(/[,]/g),".");

    return parseFloat(tarifa);

  }
  /**
   * 
   * Info
   * 
   */
  info(
    message="",
    tipo=null, 
    width=null,
    height=null,
    titulo=null
    ){

    this.dialog.open(ModalInfo,{ 
      width: width == null ? "350px" : width,
      height: height == null ? "auto" : height,
      data:{
        message: message,
        tipo: tipo,
        titulo: titulo
      },
      disableClose: true, 
      panelClass: 'no-padding'
    });

  }
  /**
   * 
   * Info
   * 
   */
  confirm(
    message="",
    fnCallback=null,
    self=null,
    dataItem=null,
    width='350px',
    height='auto'
  ){
    
    this.dialog.open(ModalConfirmComponent,{ 
      width: width,
      height: height,
      data:{
        message: message,
        dataItem: dataItem,
        self: self,
        fnCallback: fnCallback
      },
      panelClass: 'no-padding'
    });
    
  }
  /**
   * 
   * Formatar Valor
   * 
   * @param value 
   * 
   */
  formatarValor(value){

    value = value.replace(new RegExp(/[.]/g),"");
    value = value.replace(new RegExp(/[,]/g),".");

    return parseFloat(value);

  }
  /**
   * 
   * Remove acentos
   * 
   */
  removerAcentos(value){

    var string = value;
    var mapaAcentosHex 	= {
        a : /[\xE0-\xE6]/g,
        A : /[\xC0-\xC6]/g,
        e : /[\xE8-\xEB]/g,
        E : /[\xC8-\xCB]/g,
        i : /[\xEC-\xEF]/g,
        I : /[\xCC-\xCF]/g,
        o : /[\xF2-\xF6]/g,
        O : /[\xD2-\xD6]/g,
        u : /[\xF9-\xFC]/g,
        U : /[\xD9-\xDC]/g,
        c : /\xE7/g,
        C : /\xC7/g,
        n : /\xF1/g,
        N : /\xD1/g
    };

    for ( var letra in mapaAcentosHex ) {
        var expressaoRegular = mapaAcentosHex[letra];
        string = string.replace( expressaoRegular, letra );
    }

    return string; 

  }
  /**
   * 
   * Forma a string para a URL
   * 
   */
  formatarApelido(value,withVirgula=true){

    value = value.toLowerCase();
    value = value.replace(new RegExp(/[\'~:;^`´'".,)(/]/g),"");
    if(withVirgula){
      value = value.replace(new RegExp(/[' ']/g),"-");
    }  
    value = value.replace(new RegExp(/[áãâà]/g),"a");
    value = value.replace(new RegExp(/[íìî]/g),"i");
    value = value.replace(new RegExp(/[éèê]/g),"e");
    value = value.replace(new RegExp(/[òóõô]/g),"o"); 
    value = value.replace(new RegExp(/[úùû]/g),"u");

    if(withVirgula){ 
      if(value != null && typeof(value) != "undefined"){
        
        let valueOut = "";
        
        for (let index = 0; index < value.length; index++) {
                
          let current = value[index];
          let next    = value.length < (index+1) ? null : value[index+1];
        
          if((current == "-" && next == "-") || (current == " " && next == "-") || (current == "-" && next == " ")){
            valueOut += ""; 
          }else{
            valueOut += current;
          }
              
        }
        
        value = valueOut;
        
      }
    }

    return value;

  }
  
}
