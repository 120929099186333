import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'partial-header',
  templateUrl: './partial-header.component.html',
  styleUrls: ['./partial-header.component.scss']
})
export class PartialHeaderComponent implements OnInit {

  @Input("titulo") titulo = "";
  public usuario:any      = {};

  constructor(
    private data: DataService
  ){ }
  
  /**
   * 
   * Seta o usuário
   * 
   */
  setUsuario(){

    this.usuario = this.data.getUsuario();
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.setUsuario();
  }

}
