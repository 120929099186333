<div [ngSwitch]="template">
    <div *ngSwitchCase="null" class="single">
      <img src="/images/icons/alltour/whatsapp.svg" alt="Whatsapp - Aextour" (click)="open()" />
    </div>
    <div *ngSwitchCase="'topo'" class="whatsapp-topo" (click)="open()">
      <a href="javascript::void();" class="btn-whatsapp" title="Whatsapp - Gramdo Premium">
        <img src="/images/icons/whatsapp.svg" alt="WhatsApp - Gramado Premium" />
        <span>Queremos falar com você :)</span>
        <span>CLIQUE AQUI</span>
      </a>  
    </div>
    <div *ngSwitchCase="'servico'" class="whatsapp-servico" (click)="open()">
      <div class="content"> 
        <div class="icon">
          <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/whatsapp-three.svg" />
        </div>
        <div class="text">
          <span>Tem alguma dúvida?</span> 
          <span>Clique aqui e fale por WhatsApp</span>
        </div> 
      </div>   
    </div>  
    <div *ngSwitchCase="'fixo'" class="fixo">
      <div class="box-whats pulsing" (click)="open()">
        <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/whatsapp-two.svg" alt="Whatsapp - Gramado Premium" />
      </div>
    </div> 
</div>
  