<div class="file-programacao">
    <div class="file-programacao-content"> 
        <div class="bloco total">
            <div class="titulo">
                <span>TOTAL DE</span>
                <span>SERVIÇOS</span>
            </div>  
            <span class="total" [style.color]="usuario.cor_primaria">
                {{total}}
            </span>      
        </div>
        <div class="bloco time-line">
            <div class='time-line-item' *ngFor="let item of servicos;let last=last;">
                <span class="circle" [style.background]="usuario.cor_primaria" [style.border-color]="usuario.cor_secundaria"></span>
                <span class="linha" [style.background]="usuario.cor_primaria" *ngIf="!last"></span>
            </div>
        </div>
        <div class="bloco">
            <div class='item-servico' *ngFor="let item of servicos" (click)="_open(item)">
                <span [style.color]="usuario.cor_secundaria" class="nome">{{item.servico}}</span>
                <span class="data">{{item.dataServico}} {{item.horaServico}}</span>
            </div>    
        </div>   
    </div>
</div>      
