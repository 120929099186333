<div class="md-select-custom not-placeholder form-custom" (click)="_getData()" *ngIf="data == null">
    <label *ngIf="label != null">{{label}}</label>
    <select [(ngModel)]="value" #select name="_value"> 
      <option [value]="value"  *ngIf="dataCombo.length == 0 && value != null">
        {{text}}
      </option>
      <option value="">{{placeholder}}</option>
      <option *ngFor="let d of dataCombo" [value]="d.value"> 
        {{d.text}}
      </option>
    </select> 
    <div class="subscribe-message" *ngIf="loader">
      Carregando... Aguarde!
    </div>
    <div class="subscribe-message" *ngIf="response.status == 500">
      {{response.message}} 
    </div> 
  </div>  
  <div class="md-select-custom not-placeholder form-custom"  *ngIf="data != null">
    <label *ngIf="label != null">{{label}}</label> 
    <select [(ngModel)]="value" #select name="_value">
      <option value="">----</option>
      <option *ngFor="let d of data" [value]="d.value">
        {{d.text}}
      </option>
    </select>
    <div class="subscribe-message" *ngIf="loader">
      Carregando... Aguarde!
    </div>
    <div class="subscribe-message" *ngIf="response.status == 500">
      {{response.message}} 
    </div> 
  </div>  