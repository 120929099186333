<div [ngSwitch]="template">
    <div *ngSwitchCase="'default'" class="valor default {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <div class="valor-number" *ngIf="valor > 0">
        <span class="a-partir">a partir de: </span>
        <span class="cifrao" itemprop="priceCurrency">R$</span>
        <span class="valor" itemprop="price">{{valor}}</span>
      </div>
      <div class="valor-number" *ngIf="valor == 0">
        <span class="a-partir">a partir de: </span>
        <span style="display:none;" class="cifrao" itemprop="priceCurrency">R$</span>
        <span class="consulte" itemprop="price">CONSULTE!</span>
      </div>
    </div>
    <div *ngSwitchCase="'servico'" class="valor valor-servico {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <div class="content">
        <span>A partir de:</span>
        <span *ngIf="valor1 > 0">R$ {{valor1}},{{valor2}}</span> 
        <span *ngIf="valor1 == 0">CONSULTE!</span> 
      </div>  
    </div>  
</div>  
  