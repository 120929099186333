import { Component, OnInit } from '@angular/core';
import { PwaService } from 'src/app/services/pwa.service';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'btn-install-footer',
  templateUrl: './btn-install-footer.component.html',
  styleUrls: ['./btn-install-footer.component.scss']
})
export class BtnInstallFooterComponent implements OnInit {

  public installed = false;
  public color     = "";

  constructor(
    public pwa: PwaService,
    private data: DataService,
    private app: AppService  
  ) { 

    window.addEventListener('appinstalled', (evt) => {
      this.installed = true;
    });

  } 

  /***
   * 
   * Set color
   * 
   */
  setColor(){ 

    let usuario = this.data.getUsuario();

    this.color = usuario.cor_primaria;   

  }
  /***
   * 
   * Inciializa as Funções
   * 
   */
  ngOnInit():void{
    this.setColor();
  }

}
