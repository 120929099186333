<div class="mat-modal-info">
    <div class="mat-modal-info-title">
      <h4>{{titulo}}</h4>
    </div>  
    <div class="mat-modal-info-content">
      <article class="message" [innerHtml]="data.message" [ngClass]="{'alert alert-info':data.tipo == 'info','alert alert-danger':data.tipo == 'danger','alert alert-warning':data.tipo == 'warning','alert-success':data.tipo == 'success','alert': data.tipo != null}">
      </article>    
    </div>
    <div class="mat-modal-info-footer">
      <button type="modal" class="btn-one" (click)="close()">
        OK
      </button> 
    </div>   
</div>
    