import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'meus-servicos-page',
  templateUrl: './meus-servicos-page.component.html',
  styleUrls: ['./meus-servicos-page.component.scss']
})
export class MeusServicosPage implements OnInit {

  public dadosReserva:any = {};

  constructor(
    private data: DataService
  ) { } 

  /***
   * 
   * Seta os dados da reserva
   * 
   */
  setDadosReserva(){

    this.dadosReserva = this.data.getDadosReserva();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setDadosReserva();
  }

}
