<div [ngSwitch]="template">
    <div class="modulo modulo-contato" *ngSwitchCase="null">
        <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <h3>{{data.titulo}}</h3>
        </div>
        <div class="modulo-content">  
            <ul>
                <li> 
                    <a href="mailto:{{data.params.email}}" title="E-mail - Digital Movement">{{data.params.email}}</a>  
                </li>
                <li (click)="open()"> 
                    <span>{{data.params.whatsapp}}</span>   
                </li>
                <li>
                    <a href="{{data.params.instagram_link}}" title="Instagram - Digital Movement" target="_blank"> 
                        {{data.params.instagram_usuario}}
                    </a>  
                </li>
            </ul>    
        </div>    
    </div> 
    <div class="modulo modulo-page-evento" *ngSwitchCase="'page-evento'">
        <ul>
            <li>
                <div class="item">
                    <div class="icon">
                        <i class="fas fa-phone-alt"></i>
                    </div>  
                    <a href="tel:{{data.params.telefone}}" title="Telefone: {{data.params.telefone}}">
                        {{data.params.telefone}}
                    </a>    
                </div>
            </li>
            <li (click)="open()">
                <div class="item">
                    <div class="icon">
                        <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/contato/whatsapp.svg" alt="WhatsApp - Gramado Premium" />
                    </div>  
                    <span>Fale com nós pelo WhatsApp?</span>   
                </div>
            </li>
            <li>
                <div class="item">
                    <div class="icon">
                        <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/contato/email.svg" alt="E-mail - Gramado Premium" />
                    </div>    
                    <a href="mailto:{{data.params.email}}" title="E-mail: {{data.params.email}}">
                        {{data.params.email}}
                    </a>    
                </div> 
            </li>
        </ul>    
    </div> 
    <div class="modulo modulo-page-contato" *ngSwitchCase="'page-contato'">
        <div class="modulo-content">
            <ul>
                <li>
                    <div class="icon celular">
                        <i class="fa fa-phone"></i>
                    </div> 
                    <a href="tel:{{data.params.whatsapp}}" title="Telefone - Digital Movement - Soluções em Tecnologia">{{data.params.whatsapp}}</a>  
                </li>
                <li (click)="open()">
                    <div class="icon whatsapp">
                        <i class="fab fa-whatsapp"></i>
                    </div> 
                    <span>clique aqui</span>   
                </li>
                <li (click)="openInsta()"> 
                    <div class="icon">
                        <i class="fab fa-instagram"></i> 
                    </div>   
                    <a [href]="data.params.instagram_link" title="Instagram - Digital Movement - Soluções em Tecnologia" target="_blank">
                        {{data.params.instagram_usuario}}
                    </a>   
                </li>    
            </ul>
            <ul>
                <li>
                    <div class="icon">
                        <i class="fa fa-envelope"></i>
                    </div>   
                    <a href="mailto:{{data.params.email}}" title="E-mail - Digital Movement - Soluções em Tecnologia">{{data.params.email}}</a>  
                </li>
            </ul>
        </div>    
    </div>
</div>      