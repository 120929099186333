<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'"> 
    <modulo-logo [data]="m" [classCustom]="classCustom"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'banner-slider'">  
    <modulo-banner-slider [data]="m"></modulo-banner-slider>
  </div>
  <div *ngSwitchCase="'rede-social'"> 
    <modulo-rede-social [data]="m" [template]="template"></modulo-rede-social>
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [template]="template"></modulo-newsletter>
  </div>
  <div *ngSwitchCase="'whatsapp'">  
    <modulo-whatsapp [data]="m" [template]="template" [servico]="servico"></modulo-whatsapp>
  </div>
  <div *ngSwitchCase="'fale-conosco'">   
    <modulo-fale-conosco [data]="m" [template]="template"></modulo-fale-conosco>
  </div>
  <div *ngSwitchCase="'parceiros'">   
    <modulo-parceiros [data]="m"></modulo-parceiros>
  </div>
  <div *ngSwitchCase="'cases'">   
    <modulo-cases [data]="m"></modulo-cases>
  </div>
  <div *ngSwitchCase="'contato-consultor'">   
    <modulo-contato-consultor [data]="m"></modulo-contato-consultor>
  </div>
  <div *ngSwitchCase="'servicos'">     
    <modulo-servicos [data]="m" [classCustom]="classCustom" [template]="template"></modulo-servicos> 
  </div>
  <div *ngSwitchCase="'passeios-em-destaque'">     
    <modulo-passeios-em-destaque [data]="m" [template]="template"></modulo-passeios-em-destaque> 
  </div>
  <div *ngSwitchCase="'contato'">      
    <modulo-contatos [data]="m" [template]="template"></modulo-contatos> 
  </div>
  <div *ngSwitchCase="'sobre'">     
    <modulo-sobre [data]="m"[template]="template"></modulo-sobre> 
  </div>
  <div *ngSwitchCase="'localizacao'">     
    <modulo-localizacao [data]="m"[template]="template"></modulo-localizacao> 
  </div>
  <div *ngSwitchCase="'logo_brasao'">    
    <modulo-logo-brasao [data]="m"[template]="template"></modulo-logo-brasao> 
  </div>
  <div *ngSwitchCase="'certificado_cadastur'">      
    <modulo-certificado-cadastur [data]="m" [template]="template"></modulo-certificado-cadastur> 
  </div>
  <div *ngSwitchCase="'produto_em_destaque'">        
    <modulo-passeios-em-destaque [data]="m" [template]="template"></modulo-passeios-em-destaque> 
  </div>
  <div *ngSwitchCase="'banner'">  
    <modulo-banner [data]="m" [template]="template"></modulo-banner> 
  </div>
  <div *ngSwitchCase="'instagram-feed'">   
    <modulo-instagram-feed [data]="m" [template]="template"></modulo-instagram-feed>  
  </div>
  <div *ngSwitchCase="'managetour'"> 
    <modulo-managetour [data]="m" [template]="template"></modulo-managetour>  
  </div>
</div>
  
  
  