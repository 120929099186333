import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'file-servico-modal',
  templateUrl: './file-servico-modal.component.html',
  styleUrls: ['./file-servico-modal.component.scss']
})
export class FileServicoModal implements OnInit {

  public servico:any = {}; 
  public usuario:any = {};

  constructor(
    public dialog: MatDialogRef<FileServicoModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _data: DataService
  ){ }

  /***
   * 
   * Close
   * 
   */
  _close(){
    this.dialog.close();
  }
  /***
   * 
   * Seta o serviço
   * 
   * 
   */
  setServico(){

    this.servico = this.data.servico;

  }
  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setServico();
    this.usuario = this._data.getUsuario();
  }

}
