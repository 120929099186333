import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioPerfilModal } from '../usuario-perfil-modal/usuario-perfil-modal.component';

@Component({
  selector: 'usuario-perfil',
  templateUrl: './usuario-perfil.component.html',
  styleUrls: ['./usuario-perfil.component.scss']
})
export class UsuarioPerfilComponent implements OnInit {

  public usuario:any = {};
  public label            = "";

  constructor(
    private data: DataService,
    private dialog: MatDialog
  ){ }

  /**
   * 
   * Set label
   * 
   */
  setLabel(){

    try{
      
      let name = this.usuario.nomeUsuario;
     
      let data = name.split(" ");
      
      if(data.length > 1){ 

        this.label = data[0][0]+data[data.length-1][0];

      }else{

        this.label = data[0][0]+data[0][1];

      }
    }catch(e){
      
    }

  }
  /***
   * 
   * Open
   * 
   */
  open(){

    this.dialog.open(UsuarioPerfilModal,{
      width: "400px",
      height: "auto",
      disableClose: true
    });

  }
  /**
   * 
   * Set usuário 
   *
   */
  setUsuario(){

    this.usuario = this.data.getUsuario();

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{
    this.setUsuario();
    this.setLabel();
  }

}
