<div class="modulo banner-slider-slick animated fadeIn">
  <div class="swiper-button-prev sw-btn" (click)="prevSlide()">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </div>
  <div class="swiper-button-next sw-btn" (click)="nextSlide()">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </div>
  <div #banner class="banner-slider-slick-content">
      <div *ngFor="let i of data.banners;let y = index">
        <div class="item slider-item" *ngIf="!i.link_ativo">
          <!--<div class="hero-paralax"> 
            <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
          </div>-->
          <div class="hero-paralax-2"> 
            <img [src]="i.imagem" [alt]="i.alt" />
          </div>  
        </div>  
        <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
          <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
            <div class="item slider-item">
              <!--<div class="hero-paralax"> 
                <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
              </div>-->
              <div class="hero-paralax-2"> 
                <img [src]="i.imagem" [alt]="i.alt" />
              </div>  
            </div>  
          </a>
          <a [routerLink]="i.link" *ngSwitchCase="'2'">
            <div class="item slider-item">
              <!--<div class="hero-paralax"> 
                <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
              </div>-->
              <div class="hero-paralax-2"> 
                <img [src]="i.imagem" [alt]="i.alt" />
              </div>  
            </div>  
          </a> 
          <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
            <div class="item slider-item">
              <!--<div class="hero-paralax"> 
                <div class="bg" [ngStyle]="{'background-image': 'url('+i.imagem+')'}"></div>
              </div>-->
              <div class="hero-paralax-2"> 
                <img [src]="i.imagem" [alt]="i.alt" />
              </div>  
            </div>  
          </a>   
        </div>
      </div>
    </div>
</div>
  
  
  