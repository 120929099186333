<div class="modulo modulo-sobre">
    <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <h3>{{data.titulo}}</h3>
    </div>
    <div class="modulo-content">
       <article [innerHtml]="data.params.texto"></article>
       <a routerLink="sobre" title="Sobre - Gramado Premium" class="btn-custom-one"> 
           <span>LEIA MAIS</span>  
           <mat-icon>keyboard_arrow_right</mat-icon>
       </a> 
    </div>    
</div>    
