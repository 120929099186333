<div class="projeto-item">
    <span class="categoria mobile" *ngIf="projeto.categoria != null">
        {{projeto.categoria}}
    </span>
    <div class="row">
        <div class="col-md-4">
            <figure class="foto">
                <img [src]="projeto.previa_foto" [alt]="projeto.nome" />
            </figure>     
        </div> 
        <div class="col-md-8">
            <div class="content">
                <span class="categoria desktop" *ngIf="projeto.categoria != null">
                    {{projeto.categoria}}
                </span>
                <h3>{{projeto.nome}}</h3> 
                <article [innerHtml]="projeto.descricao | safeHtml"></article>
                <button type="button" (click)="openProjeto()" *ngIf="projeto.ativar_link">
                    SAIBA MAIS
                </button>    
            </div>    
        </div>    
    </div>    
</div>    
