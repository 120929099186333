<div class="page page-login">
    <div class="content-form">
        <form (submit)="login()" autocomplete="off">
            <div class="form-content">
                <div class="form-group">
                    <label>Informe o seu CPF</label>
                    <input type="text" [(ngModel)]="dataItem.cpf" name="cpf" mask="999.999.999-99" />
                </div>  
                <div class="form-group"> 
                    <label>Informe a sua Senha</label>
                    <input type="password" [(ngModel)]="dataItem.password"  name="password" />
                </div> 
                <div class="form-group">
                   <button type="submit">
                        ENTRAR
                   </button> 
                </div> 
            </div>    
            <div class="bg"></div>    
        </form>    
    </div>    
</div> 
<loader [hidden]="!loader"></loader>
