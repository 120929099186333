import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiService } from 'src/app/services/api.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPage implements OnInit {

  public dataItem:any = {};
  public loader       = false; 

  constructor(
    private app: AppService,
    private api: ApiService,
    private validator: ValidatorService,
    private data: DataService,
    private router: Router
  ) { } 

  /**
   * 
   * Login send
   * 
   */
  login(){

    try{

      this.validator.clear();

      this.validator.isEmpty(this.dataItem.cpf,"cpf","- Infore o CPF.");  
      this.validator.validCpf(this.dataItem.cpf,"cpf","- O CPF informado é inválido.");
      this.validator.isEmpty(this.dataItem.password,"password","- Informe a Senha.");

      if(this.validator.passes()){ 

        this.loader = true; 

        this.api.auth().login(this.dataItem).subscribe(response => {

          this.loader = false;

          if(response.status == 1){
            
            if(!response.data){
              this.app.info("Login inválido");
            }else{

              this.data.setUsuario(response.data);
              this.app.emitChangeUsuario.emit(response.data);   
              //this.data.setDadosReserva(response.dataReserva);
              this.router.navigateByUrl("/");

            }
          
          }else{

            this.app.info(response.data);

          }

        },(response) => {

          this.loader = false;
          let errors  = this.api.formatError(response);
          this.app.info(errors.message);    

        });

      }else{

        let errors = this.validator.getMessagesHtml();

        this.app.info(errors);

      }

    }catch(e){

      this.app.info("Houve um erro: "+e.message); 

    }

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    this.dataItem = {
      cpf: "",
      password: ""
    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {  
    this.initDataItem(); 
  }

}
