import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'ocupacao-lista-two',
  templateUrl: './ocupacao-lista-two.component.html',
  styleUrls: ['./ocupacao-lista-two.component.scss']
})
export class OcupacaoListaTwoComponent implements OnInit {

  public loader   = false;
  public response = {
    data: null,
    status: null
  }
  public data     = null;
  public content  = 1;
  public categoryData = null; 

  constructor(
    private api: ApiService,
    private app: AppService,
    private router: Router,
    private _data: DataService
  ){ }

  /***
   * 
   * Show services
   * 
   */
  showServices(data){

    this.categoryData      = data;
    this.categoryData.data = this.groupServices(data.data);
    this.content = 2;
    setTimeout(() => {
      this.app.toTop();
    });

  }
  /**
   * 
   * Get data
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.manage().ocupacao().subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.data            = response.data;
          this.content         = 1;
          this.response.status = 1;

        }else{

          let error     = this.api.formatError(response);
          this.response = {
            data: error.message,
            status: 500
          }

        }

      },(response) => {

        this.loader   = false;
        let error     = this.api.formatError(response);
        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader = false;
      this.app.info(e.message); 

    }

  }
  /***
   * 
   * Group services
   *
   */
  groupServices(services){

    let status  = false;
    let dataOut = [];
    let i       = null;

    for(let index = 0;index < services.length;index++) {
      
      i = this.hasService(services[index].idServico,dataOut);

      if(i == null){

        dataOut.push({
          id: services[index].idServico,
          name: services[index].nomeServico,
          data: [services[index]]
        });

      }else{

        dataOut[i].data.push(services[index]);

      }
      
    }

    return dataOut;

  }

  /**
   * 
   * Agrupar
   * 
   **/
  groupByCategory(data){

    let dataOut = [];
    let i = null;

    for (let index = 0; index < data.length; index++) {

      if(data[index].lastMinute === "true" || data[index].lastMinute === true){
        
        i = this.hasCategory(data[index].idCategoriaServico,dataOut);
        
        if(i == null){ 

          dataOut.push({
            id: data[index].idCategoriaServico,
            category: data[index].categoriaServico,
            data: [data[index]]
          });

        }else{

          dataOut[i].data.push(data[index]); 

        } 
      
      }
      
    }
    dataOut.sort(function (a, b) {
      if (a.category > b.category) {
        return 1;
      }
      if (a.category < b.category) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

    return dataOut;

  }
  /**
   * 
   * Exist categoria
   * 
   */
  hasCategory(categoryId,data){

    let index = null;

    for (let i = 0;i < data.length;i++) { 

      if(data[i].id == categoryId){

        index = i;  
        break;

      }
      
    }

    return index;

  }
  /**
   * 
   * Exist categoria
   * 
   */
  hasService(serviceId,data){

    let index = null;

    for (let i = 0;i < data.length;i++) { 

      if(data[i].id == serviceId){

        index = i;  
        break;

      }
      
    }

    return index;

  }
  /***
   * 
   * Sair
   * 
   */
  _sair(){

    this.app.confirm(
      "Deseja sair do sistema?",
      this.sair,
      this 
    ); 

  }
  /**
   * 
   * Sair
   * 
   */
  sair(data,self){

    self.loader = true;

    self.api.auth().logout().subscribe(response => {

      self.loader = false;

      if(response.status == 1){

        self._data.removeUsuario();
        self.router.navigateByUrl("/login");

      }else{

        let error   = self.api.formatError(response);
        self.app.info(error.message);

      }

    }, (response) => {

      self.loader = false;
      let error   = self.api.formatError(response);
      self.app.info(error.message);

    });
    

  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.getData();
  }

}
