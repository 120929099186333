<header #header class="animated-two fadeIn">
    <div class="topo"></div>  
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-12">
            <modulo posicao="logo" class="logo"></modulo>  
          </div> 
          <div class="col-lg-10">
            <div class="content-menu"> 
              <modulo-menu class="center" classCustom="menu-desktop"></modulo-menu>
            </div> 
          </div>  
        </div>  
      </div>  
    </div> 
</header>
<div class="content-menu-mobile" #menuMobile>
  <modulo-menu mobile="true" classCustom="menu-mobile" class="container-menu-mobile"></modulo-menu>
</div>   