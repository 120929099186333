import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'usuario-logo',
  templateUrl: './usuario-logo.component.html',
  styleUrls: ['./usuario-logo.component.scss']
})
export class UsuarioLogoComponent implements OnInit {

  public usuario:any = {};

  constructor(
    private data: DataService
  ) { }

  /**
   * 
   * Set Usuario
   * 
   */
  setUsuario(){ 
    this.usuario = this.data.getUsuario(); 
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.setUsuario();
  }

}
