import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePage implements OnInit {

  public atalhos     = [];
  public usuario:any = {};

  constructor(
    private app: AppService,
    private data: DataService
  ){ }

  /**
   * 
   * Seta o header
   * 
   */
  setHeader(){

    let config = this.data.getConfiguracao();

    this.app.setMetaDados(config.meta_title,config.meta_description);

  }
  /**
   * 
   * Set atalhos 
   * 
   */
  setAtalhos(){

    this.atalhos.push({
      titulo: "Meus Serviços",
      icon: "fa fa-search",
      link: "/meus-servicos"
    });
    this.atalhos.push({
      titulo: "Sugestão de Compra",
      icon: "fa fa-credit-card",
      link: "/sugestao-de-compra"
    });
    this.atalhos.push({
      titulo: "Dicas", 
      icon: "fa fa-comment",
      link: "/dicas"
    });
    this.atalhos.push({  
      titulo: "Contato",
      icon: "fa fa-envelope",
      link: "/contato"
    }); 

  }
  /**
   * 
   * Seta o usuário
   * 
   */
  setUsuario(){

    this.usuario = this.data.getUsuario(); 

  }
  /***
   * 
   * Inicializa Iniciliazação
   * 
   * 
   **/
  ngOnInit():void{
    this.app.toTop(); 
    this.setUsuario();
    this.setAtalhos();
  }


}
