<div class="produto-item {{classCustom}}" itemscope itemtype="https://schema.org/Product">
    <div class="capa" (click)="onClick()">
      <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img [lazyLoad]="produto.foto" [errorImage]="defaultImage" defaultImage="/images/pixel.png" itemprop="image" [alt]="produto.foto_alt" class="img-fluid" />
      </a> 
      <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img itemprop="image" [errorImage]="defaultImage" defaultImage="/images/pixel.png" lazyLoad="/images/produto/sem-foto/brocker.jpg" alt="Sem Foto" class="img-fluid" />
      </a> 
    </div>
    <div class="content"> 
      <div class="header"> 
        <div class="titulo"> 
          <h3 itemprop="name">
            <a (click)="onClick()" itemprop="url" [routerLink]="link" title="Saiba mais sobre: {{produto.nome}}">
              {{produto.nome}}
            </a>  
          </h3>
        </div>
      </div>  
      <div class="clearfix"></div> 
      <div class="descricao">
        <p class="clearfix">{{produto.min_descricao}}</p>
      </div>
      <div class="menor-tarifa">  
        <produto-item-valor [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
      </div> 
      <div class="footer">
        <a itemprop="url" title="Saiba mais sobre: {{produto.nome" (click)="onClick()">
          <span>SAIBA MAIS</span>
        </a> 
      </div>
    </div>  
    <span style="display:none;" itemprop="sku">{{produto.id}}</span>
  </div>
  