<div class="dm-modal">
  <div class="dm-modal-title">
    <span>Atenção</span>
  </div>  
  <div class="dm-modal-content">
    <article class="message" [innerHtml]="data.message"></article>    
  </div>
  <div class="dm-modal-footer">
    <div class="content">
      <button type="modal" (click)="delete()">
        SIM
      </button> 
      <button type="modal"  (click)="close()">
        NÃO
      </button>
    </div>   
  </div>   
</div>
      