<div class="ocupacao-lista">
    <div class="content" *ngIf="response.status == 1">
        <div class="template" *ngIf="content == 1">
            <div class="categories">
                <div class="category" *ngFor="let d of data" (click)="showServices(d)">
                    <div class="content">
                        <span class="name">{{d.category}}</span>
                        <span class="number">{{d.data.length}} Serviços</span>
                    </div>
                </div>    
            </div> 
        </div> 
        <div class="template" *ngIf="content == 2"> 
            <div class="template-title">   
                <span>{{categoryData.category}}</span>
            </div>
            <div class="services">
                <div class="service" *ngFor="let d of categoryData.data">
                    <div class="content">
                        <span class="name">{{d.name}}</span>
                        <div class="info">
                            <div class="item" *ngFor="let s of d.data">
                                <span class="date">
                                    <i class="far fa-calendar-alt"></i> 
                                    <span>{{s.Data}}</span>
                                </span> 
                                <div class="disponibility">
                                    <ul>
                                        <li>
                                            <span class='label bg-blue'>A</span>
                                            <span class='value'>{{s.Allotment}}</span>
                                        </li>
                                        <li>
                                            <span class='label bg-orange'>O</span>
                                            <span class='value'>{{s.Ocupacao}}</span>
                                        </li>
                                        <li>
                                            <span class='label bg-green'>D</span>
                                            <span class='value'>{{s.Allotment-s.Ocupacao}}</span>
                                        </li>
                                    </ul>    
                                </div>    
                            </div>    
                        </div>  
                    </div>  
                </div>    
            </div>    
        </div> 
        <div class="ocupacao-lista-footer">
            <ul>
                <li [ngClass]="{'opacity':content == 1}">   
                    <button type="button" class='btn-footer' (click)="content=1">
                        <i class="fas fa-angle-double-left"></i>
                    </button> 
                    <span>Voltar</span>
                </li>
                <li>
                    <button type="button" class='btn-footer' (click)="getData()">
                        <i class="fas fa-sync"></i>
                    </button> 
                    <span>Atualizar</span> 
                </li>
                <li>
                    <button type="button" class='btn-footer' (click)="_sair()">
                        <i class="fas fa-sign-out-alt"></i>
                    </button> 
                    <span>Sair</span> 
                </li>   
            </ul>    
        </div>    
    </div>
    <div class="content" *ngIf="response.status == 500">
        <page-error [data]="response.data" (reload)="getData()"></page-error>
    </div>
</div>   
<loader [hidden]="!loader"></loader> 
