import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ModuloService } from 'src/app/services/modulo.service';

@Component({
  selector: 'modulo',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss']
})
export class Modulo implements OnInit,AfterViewInit {

  @Input("posicao") position; 
  @Input("template") template = null;
  @Input("classCustom") classCustom = "";
  @ViewChild("content") content:ElementRef; 
  @Input("servico") servico = null; 
            
  public modulos:any = [];
  @Input("onInit") onInit:any = true;

  constructor( 
    private modulo: ModuloService
  ){}
  /**
   * 
   * Ouvintes
   * 
   */
  onData(){

    this.modulo.updateModulosEmitter.subscribe(data =>{

      this.modulos = this.modulo.filterModulosByPosition(data,this.position);

    });
    this.modulo.getModulosEmitter.emit(); 
    

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    
    if(this.onInit){
      this.onData(); 
    }  

  }
  ngAfterViewInit(){
    
    if(this.onInit == false || this.onInit == "false"){
      this.onData(); 
    }

  }

}
