import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from './pages/home-page/home-page.component';
import { ModulosResolver } from './resolvers/modulos.resolver'; 
import { ContatoPage } from './pages/contato-page/contato-page.component';
import { LoginPage } from './pages/login-page/login-page.component';
import { MeusServicosPage } from './pages/meus-servicos-page/meus-servicos-page.component';
import { DicasPage } from './pages/dicas-page/dicas-page.component';
import { SugestaoDeCompraPage } from './pages/sugestao-de-compra-page/sugestao-de-compra-page.component';
import { GuestResolver } from './resolvers/guest.resolver';

const routes: Routes = [{
  path: '',   
  component: HomePage, 
  resolve:{   
    guest: GuestResolver
  }  
},{
  path: 'login',   
  component: LoginPage 
},{
  path: '**',
  pathMatch: 'full', 
  redirectTo: ''
}];
 
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
