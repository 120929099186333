<div [ngSwitch]="template">
    <div class="modulo modulo-localizacao" *ngSwitchCase="null">
        <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <h3>{{data.titulo}}</h3>
        </div>
        <div class="modulo-content">
            <span class="icon">
                <mat-icon>location_on</mat-icon>
            </span>    
            <span class="endereco" (click)="open()">
                {{data.params.endereco}},{{data.params.numero}} - {{data.params.bairro}}<br>
                {{data.params.cidade}} - {{data.params.estado}}, {{data.params.cep}}
            </span>
        </div>    
    </div> 
    <div class="modulo modulo-localizacao modulo-localizacao-page-contato" *ngSwitchCase="'page-contato'"> 
        <iframe *ngIf="url != null" [src]="url" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div> 
</div>  