<div [ngSwitch]="template">
    <div class="modulo modulo-newsletter" *ngSwitchCase="null">
      <div class="modulo-titulo">
        <div class="container">
          <h2 *ngIf="data.mostrar_titulo">{{data.titulo}}</h2>
        </div>  
      </div>  
      <div class="modulo-content">
        <div class="container">
          <div class="row"> 
            <div class="col-lg-5 col-md-12 center">
              <article class="descricao" [innerHtml]="data.params.texto"></article>
            </div>  
            <div class="col-lg-7 col-md-12">
              <form class="form" (submit)="enviar()" autocomplete="off" autocapitalize="off">
                <div class="form-group">
                  <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Seu nome" />
                </div> 
                <div class="form-group">
                  <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Seu e-mail" />
                </div> 
                <div class="form-group">
                  <input type="tel" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Seu telefone*" phoneMask />
                </div> 
                <div class="form-group"> 
                  <button type="submit" [disabled]="loader" class="btn-two">
                    Enviar
                  </button>
                </div>
              </form>
            </div>    
          </div>
        </div>
      </div>
    </div>
</div> 
<loader [hidden]="!loader"></loader>   
  