<div class="modulo modulo-rede-social">
    <div [ngSwitch]="template">
      <div *ngSwitchCase="null">
        <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
          <h3>{{data.titulo}}</h3>
        </div>
        <div class="modulo-content modulo-content-footer">
          <ul>
            <li>
              <a href="{{data.params.facebook}}" target="__blank" title="Facebook - Gramado Premium">
                <i class="fab fa-facebook-f"></i>
              </a> 
            </li> 
            <li> 
              <a href="{{data.params.instagram}}" target="__blank" title="Instagram - Gramado Premium">
                <i class="fab fa-instagram"></i>
              </a>  
            </li>
            <li>
              <a href="{{data.params.youtube}}" target="__blank" title="Youtube - Gramado Premium">
                <i class="fab fa-youtube"></i>
              </a>  
            </li> 
          </ul>  
        </div>  
      </div>
      <div *ngSwitchCase="'topo'" class="topo"> 
        <strong>{{data.titulo}}</strong>
        <ul>
          <li>
            <a href="{{data.params.instagram}}" target="__blank" title="Instagram - Gramado Premium">
              <img src="/images/icons/rede-social/instagram.svg" alt="Instagram - Gramado Premium" />
            </a>  
          </li>
          <li> 
            <a href="{{data.params.facebook}}" target="__blank" title="Facebook - Gramado Premium">
              <img src="/images/icons/rede-social/facebook.svg" alt="Facebook - Gramado Premium" />
            </a> 
          </li> 
        </ul> 
      </div>
      <div *ngSwitchCase="'footer'" class="footer"> 
        <h3>Nos siga nas redes sociais</h3>
        <ul>
          <li>
            <a href="{{data.params.instagram}}" target="__blank" title="Instagram - Gramado Premium">
              <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/rede-social/instagram-02.svg" alt="Instagram - Gramado Premium" />
            </a>  
          </li>
          <li> 
            <a href="{{data.params.facebook}}" target="__blank" title="Facebook - Gramado Premium">
              <img defaultImage="/images/pixel.png" lazyLoad="/images/icons/rede-social/facebook-02.svg" alt="Facebook - Gramado Premium" />
            </a> 
          </li> 
        </ul> 
      </div>
    </div>  
</div>
  