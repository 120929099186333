<div class="modulo modulo-contato-consultor">
    <div class="modulo-content">   
        <div class="container">
            <article class="descricao" [innerHtml]="data.params.texto"></article>
            <div class="content-form">
                <form class="form" autocomplete="off" (submit)="enviar()">
                    <div class="form-group">
                        <label>INFORME SEU NOME:</label>
                        <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
                    </div> 
                    <div class="form-group">
                        <label>INFORME SEU E-MAIL:</label>
                        <input type="text" [(ngModel)]="dataItem.email" name="email" />
                    </div> 
                    <div class="form-group">
                        <label>INFORME SEU TELEFONE:</label>
                        <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" phoneMask />
                    </div> 
                    <div class="form-group">
                        <label>DEIXE UMA MENSAGEM:</label>
                        <textarea [(ngModel)]="dataItem.mensagem" name="mensagem"></textarea>
                    </div> 
                    <div class="form-group form-footer">
                        <button type="submit" class="btn-custom-one">
                            <span>ENVIAR</span>
                            <mat-icon>keyboard_arrow_right</mat-icon>
                        </button>    
                    </div>   
                </form>    
            </div> 
            <article class="frase" [innerHtml]="data.params.frase | safeHtml"></article>   
        </div>    
    </div> 
    <div class="bg-01"></div>
    <div class="bg-02"></div>   
</div>   
<loader [hidden]="!loader"></loader> 