<div class="footer">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-4">
                    <empresas-parceiras-partial></empresas-parceiras-partial>
                </div>   
                <div class="col-lg-3 col-md-4">
                    <modulo posicao="contato"></modulo>                  
                </div>   
                <div class="col-lg-6 col-md-4">
                    <modulo posicao="fale-conosco"></modulo> 
                </div>   
            </div>    
        </div>    
    </div>    
    <div class="content-copyright">
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <span class="copy-left">Digital Movement - Todos os direitos reservados - 2020</span>
                </div>
                <div class="col-6">       
                    <menu-footer></menu-footer> 
                </div>
            </div>        
        </div>    
    </div>    
</div>    
