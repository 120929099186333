<div class="dm-modal">
    <div class="dm-modal-header">
        <h3>Agendamento de Serviço</h3>
        <span class="dm-close" (click)="dialog.close()">
            <mat-icon>cancel</mat-icon>
        </span> 
    </div>
    <div class="dm-modal-content">  
        <form class="form form-custom" (submit)="enviar()">
            <div class="form-group"> 
                <label>Seu nome:</label>
                <input type="text" [(ngModel)]="dataItem.nome" name="nome" />
            </div> 
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>E-mail:</label>
                        <input type="text" [(ngModel)]="dataItem.email" name="email" />
                    </div> 
                </div>
                <div class="col-md-6">       
                    <div class="form-group">
                        <label>Telefone:</label>
                        <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" phoneMask />
                    </div> 
                </div>
            </div>
            <div class="form-group"> 
                <label>Deixe uma mensagem:</label>
                <textarea type="text" [(ngModel)]="dataItem.mensagem" name="mensagem" rows="5"></textarea>
            </div> 
            <div class="form-group">
                <button type="submit" class="btn-one">
                    <span>ENVIAR</span>
                </button>    
            </div>    
        </form>    
    </div>
</div> 
<loader [hidden]="!loader"></loader>       
