<div class="produto-form-search-two">
    <div class="titulo" (click)="_showContent()">
      <h3>ENCONTRE SEU PASSEIO IDEAL</h3>
      <mat-icon>keyboard_arrow_down</mat-icon> 
    </div>
    <div class="content" [ngClass]="{'showContent':showContent}">  
      <form class="form form-custom" autocomplete="off" (submit)="pesquisar()">
        <div class="form-group">
            <label>TERMO:</label> 
            <input type="text" [(ngModel)]="dataItem.termo" name="termo" />
        </div>
        <div class="form-group">
          <label>CIDADES:</label>
          <mat-select [(ngModel)]="dataItem.produto_cidade_id" name="produto_cidade_id">
            <mat-option value="">
              Todas as Cidades
            </mat-option>  
            <mat-option [value]="cidade.id" *ngFor="let cidade of cidades">
              {{cidade.nome}}
            </mat-option>
          </mat-select> 
        </div> 
        <div class="form-group">
          <label>CLASSIFICAÇÃO:</label>
          <mat-select [(ngModel)]="dataItem.produto_classificacao_id" name="produto_classificacao_id">
            <mat-option value="">
              Todas as Classificações
            </mat-option>  
            <mat-option [value]="c.id" *ngFor="let c of classificacao">
              {{c.titulo}}
            </mat-option>
          </mat-select>
        </div> 
        <div class="form-group">
          <label>PERÍODO:</label>
          <mat-select [(ngModel)]="dataItem.tipo_periodo_id" name="tipo_periodo_id">
            <mat-option value="">
              Todos os Períodos
            </mat-option>  
            <mat-option [value]="p.id" *ngFor="let p of periodos">
              {{p.descricao}}
            </mat-option>
          </mat-select>
        </div>  
        <div class="form-group" *ngIf="dataItem.apelido_categoria == null">
          <label>CATEGORIA:</label>
          <mat-select [(ngModel)]="dataItem.produto_categoria_id" name="produto_categoria_id">
            <mat-option value="">
              Todas as Categorias
            </mat-option>  
            <mat-option [value]="c.id" *ngFor="let c of categorias">
              {{c.titulo}}
            </mat-option>
          </mat-select>
        </div> 
        <div class="form-group">
          <button type="submit" class="btn-one" [disabled]="loader">
            Pesquisar
          </button>  
        </div>  
      </form>  
    </div>  
  </div>
  <loader [hidden]="!loader"></loader>
  