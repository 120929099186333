<div class="header" [style.background]="usuario.cor_primaria">
    <div [style.background]="usuario.cor_secundaria" class="linha"></div>
    <div class="bloco one">
        <div class="container">
            <sidebar-menu-button></sidebar-menu-button>
        </div>
    </div>    
    <div class="bloco two">
        <div class="container">
            <div class="separator">
                <span></span>
                <span></span>
            </div>   
            <h1 *ngIf="titulo !== ''">{{titulo}}</h1>
            <usuario-perfil></usuario-perfil>
        </div>    
    </div>  
</div>     