<div class="dm-modal">
    <div class="dm-modal-header">
        <span>
            <mat-icon>person</mat-icon>
            Fale com um dos nossos consultores
        </span> 
        <span class="dm-close" (click)="dialog.close()">
            <mat-icon>cancel</mat-icon>
        </span>    
    </div>    
    <div class="dm-modal-content">
        <form class="form-custom" (submit)="enviar()">
            <div class="form-group">
                <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Seu nome" />
            </div> 
            <div class="form-group">
                <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Seu e-mail" />
            </div> 
            <div class="form-group">
                <input type="text" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Seu telefone" phoneMask />
            </div>
            <div class="form-group">
                <textarea [(ngModel)]="dataItem.mensagem" name="mensagem" [placeholder]="labelMensagem"></textarea>
            </div>  
            <div class="form-group dm-modal-footer">
                <button type="submit" class="btn-one center">
                    ENVIAR
                </button>    
            </div>    
        </form>    
    </div>    
</div> 
<loader message="Enviando. Aguarde!" [hidden]="!loader"></loader>   
