import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'dicas-page',
  templateUrl: './dicas-page.component.html',
  styleUrls: ['./dicas-page.component.scss']
})
export class DicasPage implements OnInit {

  public usuario:any = {};

  constructor(
    private data: DataService
  ) { } 

  /***
   * 
   * Seta os dados
   * 
   */
  setData(){

    this.usuario = this.data.getUsuario();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setData();
  }

}
