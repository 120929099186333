<div class="modulo modulo-servicos {{classCustom}}">
    <div class="modulo-titulo">
        <div class="container">
            <h2>{{data.titulo}}</h2>
        </div>    
    </div>
    <div class="modulo-content"> 
        <section class="section">
            <div class="container">
                <div class="servicos">
                    <div class="row">
                        <div class="col-md-4" *ngFor="let s of data.servicos">
                            <div class="item">
                                <div class="icone">  
                                    <img [src]="s.icone" [alt]="s.titulo" />
                                </div> 
                                <h3>{{s.titulo}}</h3>   
                                <article [innerHtml]="s.descricao | safeHtml"></article> 
                            </div>    
                        </div>    
                    </div>    
                </div>    
            </div> 
        </section>
        <a routerLink="contato" title="Contato" class='btn-two radius btn-contato'>
            Entre em Contato
        </a>    
    </div>    
</div>    