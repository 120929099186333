import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';
import { NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'] 
})
export class SidebarMenuComponent implements OnInit {

  public show = false;
  public on   = false;

  constructor(
    private menu: MenuService,
    private router: Router,
    private app: AppService,
    private data: DataService
  ) { }

  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.menu.menuToggleOpen.subscribe(data => {
      this.show = true;
      this.on = true;
    });
    this.menu.menuToggleClose.subscribe(data => {
      this.show = false;
    }); 
    this.router.events.forEach((event:NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        this.close(); 
      }
      

    });
  }
  /**
   * 
   * Close
   * 
   */
  close(){

    this.on = false;

    setTimeout(() => {
      this.show = false;
    },250);
  
  }
  /***
   * 
   * Sair
   * 
   */
  _sair(){

    this.app.confirm(
      "Deseja sair do sistema?",
      this.sair,
      this
    );

  }
  /**
   * 
   * Sair
   * 
   */
  sair(data,self){

    self.data.removeUsuario();
    self.router.navigateByUrl("/login");
    self.show = false;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.onData();
  }

}
