<div class="ocupacao-lista">
    <div class="content" *ngIf="response.status == 1">
        <div class="dia" *ngFor="let d of data;let i=index;">
            <div class="header"> 
                <i class="fas fa-calendar-alt"></i>
                <span>{{d.data}}</span>
            </div>  
            <div class="content" *ngIf="d.servicos.length > 0">
                <div class="item" *ngFor="let s of d.servicos">
                    <span class="name">
                        {{s.nomeServico}}
                    </span>
                    <span class='value'>
                        {{s.Allotment-s.Ocupacao}}
                    </span>
                </div>    
            </div>  
            <div class="content" *ngIf="d.servicos.length == 0">
                <p>Nenhum servico para mostrar.</p>
            </div>  
        </div>    
        <div class="ocupacao-lista-footer">
            <ul>
                <li>   
                    <btn-install-footer></btn-install-footer>  
                </li>
                <li>
                    <button type="button" class='btn-footer' (click)="getData()">
                        <i class="fas fa-sync"></i>
                    </button> 
                    <span>Atualizar</span> 
                </li>
                <li>
                    <button type="button" class='btn-footer' (click)="_sair()">
                        <i class="fas fa-sign-out-alt"></i>
                    </button> 
                    <span>Sair</span> 
                </li>   
            </ul>    
        </div>    
    </div>
    <div class="content" *ngIf="response.status == 500">
        <page-error [data]="response.data" (reload)="getData()"></page-error>
    </div>
</div>   
<loader [hidden]="!loader"></loader> 

