<div class="modulo">
    <div class="modulo-titulo">
        <h3>Empresas Parceiras</h3>
    </div>
    <div class="modulo-content">
        <ul>
            <li>
                <a href="http://penhasolutions.com.br/" target="_blank" title="Penha Solutions - Especialista em Turismo Receptivo">
                    Penha Solutions
                </a>    
            </li>
            <li>
                <a href="https://designcreate.com.br/" target="_blank" title="Design Create - Design Criativo">
                    Design Create
                </a>  
            </li>
        </ul>    
    </div>    
</div>    
