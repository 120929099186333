<div class="sidebar-menu" [ngClass]="{'slide-right':on,'slide-right-out':!on}" [hidden]="!show">
    <div class="sidebar-menu-header">

    </div>    
    <div class="sidebar-menu-content">
        <nav class="menu">
            <ul>
                <li>
                    <a routerLink="/" title="Meus Serviços - Manage MyTrip">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        <span>Meus Servicos</span>
                    </a>    
                </li>
                <li>
                    <a routerLink="/sugestao-de-compra" title="Sugestão de Compra - Manage MyTrip">
                        <i class="fa fa-credit-card" aria-hidden="true"></i>
                        <span>Sugestão de Compra</span>
                    </a>    
                </li>
                <li>
                    <a routerLink="/dicas" title="Dicas - Manage MyTrip">
                        <i class="fa fa-comment" aria-hidden="true"></i>
                        <span>Dicas</span>
                    </a>    
                </li>
                <li>
                    <a routerLink="/contato" title="Contato- Manage MyTrip">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <span>Contato</span>
                    </a>    
                </li>
                <li>
                    <a href="javascript::void();" title="Sair - Manage MyTrip" (click)="_sair()">
                        <i class="fas fa-sign-out-alt"></i>
                        <span>Sair</span>
                    </a>    
                </li>   
            </ul>    
        </nav>    
    </div>  
    <div class="sidebar-menu-footer">
        <figure>
            <img src="/assets/logos/managetour/logo.svg" alt="Manage MyTrip" />
        </figure>     
    </div>      
</div>  
<div class="sidebar-menu-close" [hidden]="!show" (click)="close()">
    <img src="/images/icons/close.svg" alt="Close" />
</div>    
<div class="overlay" [ngClass]="{'fadeIn':on,'fadeOut':!on}" [hidden]="!show"></div>   
