import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileServicoModal } from '../../meus-servicos/file-servico-modal/file-servico-modal.component';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'usuario-perfil-modal',
  templateUrl: './usuario-perfil-modal.component.html',
  styleUrls: ['./usuario-perfil-modal.component.scss']
})
export class UsuarioPerfilModal implements OnInit {

  public usuario:any = {};
  public reserva:any = null;

  constructor(
    public dialog: MatDialogRef<FileServicoModal>,
    @Inject(MAT_DIALOG_DATA) public data,
    private _data: DataService,
    private app: AppService,
    private api: ApiService, 
    private router: Router 
  ) { }

  /***
   * 
   * Sair
   * 
   */
  _sair(){

    this.app.confirm(
      "Deseja sair do sistema?",
      this.sair,
      this 
    ); 

  }
  /**
   * 
   * Sair
   * 
   */
  sair(data,self){

    self.loader = true;

    self.api.auth().logout().subscribe(response => {

      self.loader = false;

      if(response.status == 1){

        self._data.removeUsuario();
        self.router.navigateByUrl("/login");
        self._close();

      }else{

        let error   = self.api.formatError(response);
        self.app.info(error.message);

      }

    }, (response) => {

      self.loader = false;
      let error   = self.api.formatError(response);
      self.app.info(error.message);

    });

  }
  /***
   * 
   * Close
   * 
   */
  _close(){  
    this.dialog.close();
  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(): void {
    this.usuario = this._data.getUsuario();
  }

}
