import { Directive, HostListener, ElementRef, Renderer2, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR,DefaultValueAccessor} from "@angular/forms";

const INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UppercaseDirective),
  multi: true,
};

@Directive({
  selector: '[uppercase]',  
  providers: [
    INPUT_CONTROL_VALUE_ACCESSOR, 
  ]
})
export class UppercaseDirective extends DefaultValueAccessor {

  constructor(
    private renderer2: Renderer2, 
    private el: ElementRef
  ) {
    super(renderer2,el,false); 
  }

  /**
   * 
   * On Input
   * 
   */
  @HostListener('input',['$event'])  
  onInput($event: any){ 
    let value = this.formatValue($event);
    super.writeValue(value); 
    this.onChange(value); 

  }
  /**
   * 
   * Formata o valor
   * 
   * @param $event 
   * 
   */
  private formatValue($event:any){ 

    return $event.target.value.toUpperCase();  

  }

}
