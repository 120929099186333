<div class="file-estada">  
    <div class="file-estada-content">
        <div class="icon">
            <i [style.color]="usuario.cor_secundaria" class="fas fa-calendar-alt"></i>
        </div> 
        <div class="content-data" [style.color]="usuario.cor_primaria">
            <div class="date date-in">
                <span class="mes">{{dataIn.mes}}</span>
                <span class="dia" [style.color]="usuario.cor_secundaria">{{dataIn.dia}}</span>
                <span class="ano">{{dataIn.ano}}</span>
            </div> 
            <div class="divisor">
                <div class="linha" [style.background]="usuario.cor_primaria"></div>
            </div>    
            <div class="date date-out">
                <span class="mes">{{dataOut.mes}}</span>
                <span class="dia" [style.color]="usuario.cor_secundaria">{{dataOut.dia}}</span> 
                <span class="ano">{{dataOut.ano}}</span>
            </div>    
        </div>     
    </div>    
</div>      
