<div class="modulo">
    <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <h2>{{data.titulo}}</h2> 
        <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
        <div class="divisor-titulo">
            <span></span>
            <span></span>
            <span></span>
        </div> 
    </div>
    <div class="modulo-content">
        <section class="section-servicos">
            <div class="container">
                <div class="row">
                    <div class="col-md-4" *ngFor="let p of data.produtos">
                        <produto-item [data]="p"></produto-item>
                    </div>    
                </div>
            </div>
        </section>
        <div class="ver-todos">
            <a routerLink="servicos" title="Ver todos os serviços da Gramado Premium">
                <span>VER TODOS OS PASSEIOS</span>
                <span class="linha"></span>
            </a>    
        </div>     
    </div>    
</div>    