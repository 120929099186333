import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePage } from './pages/home-page/home-page.component';
import { Modulo } from './modulos/modulo/modulo.component';
import { ModuloLogo } from './modulos/logo/logo.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModuloService } from './services/modulo.service';
import { DataService } from './services/data.service';
import { AppService } from './services/app.service'; 
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { BannerSliderComponent } from './modulos/banner-slider/banner-slider.component';
import { RedeSocialComponent } from './modulos/rede-social/rede-social.component';
import { NewsletterComponent } from './modulos/newsletter/newsletter.component';
import { WhatsappComponent } from './modulos/whatsapp/whatsapp.component';
import { LazyLoadImageModule } from 'ng-lazyload-image'; //@3.4.2
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { FaleConoscoComponent } from './modulos/fale-conosco/fale-conosco.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ParceirosComponent } from './modulos/parceiros/parceiros.component';  
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContatoPage } from './pages/contato-page/contato-page.component';
import { CasesComponent } from './modulos/cases/cases.component';
import { ModalInfo } from './components/modal/modal-info/modal-info.component';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { ContatoConsultorComponent } from './modulos/contato-consultor/contato-consultor.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ServicosComponent } from './modulos/servicos/servicos.component'; 
import { GtagService } from './services/gtag.service';
import { PageErrorComponent } from './components/error/page-error/page-error.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { MenuServicosComponent } from './components/menu-servicos/menu-servicos.component';
import { FuncionalidadeItemComponent } from './components/servicos/funcionalidade-item/funcionalidade-item.component';
import { CaseItemComponent } from './components/cases/case-item/case-item.component';
import { ModalSugestao } from './components/modal/modal-sugestao/modal-sugestao.component';
import { FormBuscaComponent } from './components/busca/form-busca/form-busca.component';
import { PasseiosEmDestaqueComponent } from './modulos/passeios-em-destaque/passeios-em-destaque.component';
import { ContatosComponent } from './modulos/contatos/contatos.component';
import { MenuFooterComponent } from './components/menu-footer/menu-footer.component';
import { LocalizacaoComponent } from './modulos/localizacao/localizacao.component';
import { SobreComponent } from './modulos/sobre/sobre.component';
import { LogoBrasaoComponent } from './modulos/logo-brasao/logo-brasao.component';
import { CertificadoCadasturComponent } from './modulos/certificado-cadastur/certificado-cadastur.component';
import { GaleriaLightComponent } from './components/galeria/galeria-light/galeria-light.component';
import { ModalConsultor } from './components/modal/modal-consultor/modal-consultor.component';
import { ProdutoItemComponent } from './components/produto/produto-item/produto-item.component';
import { ProdutoItemValorComponent } from './components/produto/produto-item-valor/produto-item-valor.component';
import { ProdutoFotoComponent } from './components/produto/produto-foto/produto-foto.component';
import { ProdutoFormSearchComponent } from './components/produto/produto-form-search/produto-form-search.component';
import { MaskDirective } from './directives/mask.directive';
import { FormatDuracaoPipe } from './pipes/format-duracao.pipe';
import { ProdutoFormAgendamento } from './components/produto/produto-form-agendamento/produto-form-agendamento.component';
import { EventoDateFormatPipe } from './pipes/evento-date-format.pipe'; 
import { DateService } from './services/date.service';
import {MatDatepickerModule} from '@angular/material/datepicker';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { MatNativeDateModule, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from './helpers/format-datepicker';
import { RouterModule } from '@angular/router';
import { BannerComponent } from './modulos/banner/banner.component';
import { EmpresasParceirasPartialComponent } from './components/partial/empresas-parceiras-partial/empresas-parceiras-partial.component';
import { InstagramFeedComponent } from './modulos/instagram-feed/instagram-feed.component';
import { ManagetourComponent } from './modulos/managetour/managetour.component';
import { MaquinaEscreverPipe } from './pipes/maquina-escrever.pipe';
import { ProjetoItemComponent } from './components/projeto/projeto-item/projeto-item.component';
import { ProjetoModal } from './components/projeto/projeto-modal/projeto-modal.component';
import { ValidatorService } from './services/validator.service';
import { ComboSelect } from './components/combos/combo-select/combo-select.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginPage } from './pages/login-page/login-page.component';
import { UppercaseDirective } from './directives/uppercase.directive';
import { PartialHeaderComponent } from './components/partial/partial-header/partial-header.component';
import { UsuarioPerfilComponent } from './components/usuario/usuario-perfil/usuario-perfil.component';
import { SidebarMenuButtonComponent } from './components/sidebar/sidebar-menu-button/sidebar-menu-button.component';
import { SidebarMenuComponent } from './components/sidebar/sidebar-menu/sidebar-menu.component';
import { MeusServicosPage } from './pages/meus-servicos-page/meus-servicos-page.component';
import { SugestaoDeCompraPage } from './pages/sugestao-de-compra-page/sugestao-de-compra-page.component';
import { DicasPage } from './pages/dicas-page/dicas-page.component';
import { UsuarioInfoComponent } from './components/usuario/usuario-info/usuario-info.component';
import { UsuarioLogoComponent } from './components/usuario/usuario-logo/usuario-logo.component';
import { FileEstadaComponent } from './components/meus-servicos/file-estada/file-estada.component';
import { FileProgramacaoComponent } from './components/meus-servicos/file-programacao/file-programacao.component';
import { FileServicoModal } from './components/meus-servicos/file-servico-modal/file-servico-modal.component';
import { NomeLabelPipe } from './pipes/nome-label.pipe';
import { ModalConfirmComponent } from './components/modal/modal-confirm/modal-confirm.component';
import { UsuarioPerfilModal } from './components/usuario/usuario-perfil-modal/usuario-perfil-modal.component';
import { BtnInstallComponent } from './components/btns/btn-install/btn-install.component';
import { PwaService } from './services/pwa.service';
import { BtnInstallFooterComponent } from './components/btns/btn-install-footer/btn-install-footer.component';
import { OcupacaoListaComponent } from './components/ocupacao/ocupacao-lista/ocupacao-lista.component';
import { OcupacaoListaTwoComponent } from './components/ocupacao/ocupacao-lista-two/ocupacao-lista-two.component';
 
registerLocaleData(localePt, 'pt-BR');    

@NgModule({
  declarations: [ 
    AppComponent, 
    HomePage,
    Modulo,
    ModuloLogo, 
    MenuComponent,
    HeaderComponent,
    BannerSliderComponent,
    RedeSocialComponent,
    NewsletterComponent,
    WhatsappComponent,
    FooterComponent,
    FaleConoscoComponent,
    PhoneMaskDirective,
    ParceirosComponent,
    ContatoPage,
    CasesComponent,
    ModalInfo,
    LoaderComponent,
    ContatoConsultorComponent,
    SafeHtmlPipe, 
    ServicosComponent,
    PageErrorComponent,
    BreadcrumbsComponent,
    MenuServicosComponent,
    FuncionalidadeItemComponent,
    CaseItemComponent,
    ModalSugestao,
    FormBuscaComponent,
    PasseiosEmDestaqueComponent,
    ContatosComponent,
    MenuFooterComponent,
    LocalizacaoComponent,
    SobreComponent,
    LogoBrasaoComponent,
    CertificadoCadasturComponent,
    GaleriaLightComponent,
    ModalConsultor,
    ProdutoItemComponent,
    ProdutoItemValorComponent,
    ProdutoFotoComponent,
    ProdutoFormSearchComponent,
    MaskDirective,
    FormatDuracaoPipe,
    ProdutoFormAgendamento,
    EventoDateFormatPipe,
    BannerComponent,
    EmpresasParceirasPartialComponent,
    InstagramFeedComponent,
    ManagetourComponent,
    MaquinaEscreverPipe,  
    ProjetoItemComponent, 
    ProjetoModal, 
    ComboSelect, 
    LoginPage, 
    UppercaseDirective, 
    PartialHeaderComponent, 
    UsuarioPerfilComponent, 
    SidebarMenuButtonComponent, 
    SidebarMenuComponent, 
    MeusServicosPage, 
    SugestaoDeCompraPage, 
    DicasPage, 
    UsuarioInfoComponent, 
    UsuarioLogoComponent, 
    FileEstadaComponent, 
    FileProgramacaoComponent, 
    FileServicoModal, 
    NomeLabelPipe, 
    ModalConfirmComponent, 
    UsuarioPerfilModal,
    BtnInstallComponent,
    BtnInstallFooterComponent,
    OcupacaoListaComponent,
    OcupacaoListaTwoComponent
  ],
  entryComponents: [
    ModalInfo,
    ModalSugestao,
    ProdutoFormAgendamento,
    ProjetoModal,
    FileServicoModal,
    ModalConfirmComponent,   
    UsuarioPerfilModal
  ],
  imports: [
    HttpClientModule, 
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    LazyLoadImageModule, 
    DeviceDetectorModule.forRoot(), 
    FormsModule,
    MatIconModule,
    MatDialogModule,
    MatRadioModule,
    MatSelectModule,
    MatNativeDateModule, 
    MatDatepickerModule,   
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: true,registrationStrategy: 'registerImmediately'})  
  ],
  providers: [
    ModuloService,
    DataService,
    AppService,
    GtagService,              
    DateService,
    ValidatorService, 
    PwaService,
    {provide: LOCALE_ID, useValue: 'pt-BR'},     
    {provide: MAT_DATE_FORMATS,useValue: APP_DATE_FORMATS} 
  ],
  bootstrap: [AppComponent]
})
export class AppModule{}   
