import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'sugestao-de-compra-page',
  templateUrl: './sugestao-de-compra-page.component.html',
  styleUrls: ['./sugestao-de-compra-page.component.scss']
})
export class SugestaoDeCompraPage implements OnInit {

  public usuario:any     = {};
  public servicos:any    = [];

  constructor(
    private data: DataService 
  ){ }

  /***
   * 
   * Set data
   * 
   */
  setData(){ 

    this.usuario     = this.data.getUsuario();
    let dataReserva = this.data.getDadosReserva();

    this.servicos = dataReserva.servicosSugeridos;

  }
  _show(servico){

    servico.show = servico.show ? false : true;

  }
  /**
   * 
   * Inicializa as Funções  
   * 
   */
  ngOnInit(): void {
    this.setData();
  }

}
