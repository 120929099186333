<div class="case-item animated fadeIn">
    <div class="foto" *ngIf="data.ativar_link">
        <a [href]="data.link_site" target="_blank" [title]="data.nome">
            <img defaultImage="/images/pixel.png" [lazyLoad]="data.previa_foto" class="img-fluid" >
        </a>
        <a [href]="data.link_site" class="btn-one saiba-mais" target="_blank" [title]="data.nome">
            SAIBA MAIS
        </a>   
    </div> 
    <div class="foto" *ngIf="!data.ativar_link">
        <img defaultImage="/images/pixel.png" [lazyLoad]="data.previa_foto" class="img-fluid" >
    </div>   
</div>  
