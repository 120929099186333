import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'usuario-info',
  templateUrl: './usuario-info.component.html',
  styleUrls: ['./usuario-info.component.scss']
})
export class UsuarioInfoComponent implements OnInit {

  @Input("template") template = "one";
  public dadosReserva:any = null;
  public usuario:any = {};
  
  constructor(
    private data: DataService 
  ){ }

  /**
   * 
   * Set data file
   * 
   */
  setDadosReserva(){
    this.dadosReserva = this.data.getDadosReserva();
  }
  /**
   * 
   * Set data file
   * 
   */
  setUsuario(){

    this.usuario = this.data.getUsuario();

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.setUsuario(); 
  }

}
