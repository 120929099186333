<div class="menu {{classCustom}}">
  <div class="button-menu" (click)="open()">
    <mat-icon>menu</mat-icon>
  </div>
  <div class="menu-content" [ngClass]="{'show fadeInLeftBig':showMenu && mobile,'hide':!showMenu && mobile}"> 
    <div class="header" *ngIf="showMenu">
      <modulo posicao="logo" classCustom="header-mobile"></modulo>
    </div>  
    <ul itemscope itemtype="https://schema.org/Menu">

      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="sobre">
          <span itemprop="name">SOBRE A DM</span> 
          <i class="fa fa-heart" aria-hidden="true"></i>
        </a>
      </li>
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="projetos">
          <span itemprop="name">PROJETOS</span>
        </a>
      </li> 
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="orcamentos"> 
          <span itemprop="name">ORÇAMENTOS</span>
        </a>
      </li> 
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="contato">
          <span itemprop="name">CONTATO</span>
        </a>
      </li> 
      <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="contato">
          <span itemprop="name">SAIR</span>
        </a>
      </li> 
    </ul> 
    <menu-servicos class="_desktop" [hidden]="!subMenu && !mobile"></menu-servicos> 
  </div> 
</div>    
