<div class="box-form-busca-bg">
    <div class="box-form-busca">
        <div class="header">
            <span class="titulo">QUEREMOS AJUDAR VOCÊ!</span>
            <span class="subtitulo">O que você está buscando?</span>
        </div>    
        <div class="content">
            <form class="form" autocomplete="off" (submit)="send()">
                <div class="form-group">
                    <mat-radio-group [(ngModel)]="categoria" name="categoria">
                        <mat-radio-button value="1">Eventos</mat-radio-button>
                        <mat-radio-button value="2">Passeios</mat-radio-button>
                        <mat-radio-button value="3">Ingressos</mat-radio-button>
                        <mat-radio-button value="4">Transfers</mat-radio-button>
                    </mat-radio-group> 
                </div>
                <div class="form-group">
                    <input type="text" [(ngModel)]="termo" name="termo" [placeholder]="_placeholder" autocomplete="_termo" />
                </div> 
                <div class="form-group">
                    <button type="submit" class="btn-one center">
                        <span>BUSCAR</span>
                    </button>    
                </div>    
            </form>    
        </div>    
    </div>  
</div>  
