<div class="modulo instagram-feed">
   <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <div class="container">
        <h2>{{data.titulo}}</h2> 
        <img src="/images/icons/instagram.svg" alt="Instagram - Digital Movement" /> 
    </div> 
   </div>
   <div class="modulo-content">
        <div class="posts">
            <div class="container">  
                <div class="row">
                    <div class="col-md-4" *ngFor="let p of data.posts"> 
                        <div class="post-content" [innerHtml]="p.codigo | safeHtml"></div>
                    </div>    
                </div>  
            </div>  
        </div>
        <a href="https://www.instagram.com/digitalmovementrs/" target="_blank" title="Digital Movement - Soluções em Tecnologia" class="btn-two radius">
            <span>Nos siga</span>
            <i class="fa fa-heart" aria-hidden="true"></i>  
        </a>    
    </div>
</div>
