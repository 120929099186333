<div class="modulo">
    <div class="modulo-content"> 
        <ul itemscope itemtype="https://schema.org/Menu">
            <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem">
                <a routerLink="projetos">
                  <span itemprop="name">Projetos</span>
                </a>
            </li>
            <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem">
                <a routerLink="orcamentos"> 
                  <span itemprop="name">Orçamentos</span>
                </a>
            </li>
            <li itemprop="hasMenuItem" itemscope itemtype="https://schema.org/MenuItem">
                <a routerLink="contato">
                  <span itemprop="name">Contato</span>
                </a>
            </li>
        </ul>
    </div>     
</div>    