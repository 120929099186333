import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input("message") message : string = "";


  constructor() { }

  /***
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){} 

}
