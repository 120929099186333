import { Component, OnInit } from '@angular/core';
import { PwaService } from 'src/app/services/pwa.service';
import { DataService } from 'src/app/services/data.service';
import { AppService } from 'src/app/services/app.service';

declare var window:any;
declare var navigator:any;
import pwaInstallPrompt from "pwa-install-prompt";

declare var window:any;

@Component({
  selector: 'btn-install',
  templateUrl: './btn-install.component.html',
  styleUrls: ['./btn-install.component.scss']
})
export class BtnInstallComponent implements OnInit {

  public usuario:any = {};   
  public installed   = false;

  constructor(
    public pwa: PwaService,
    private data: DataService,
    private app: AppService
  ){ 
    
    this.usuario = this.data.getUsuario();   
    window.addEventListener('appinstalled', (evt) => {
      this.installed = true;
    });

  }

  /**
   * 
   * Intall
   * 
   */
  install(data,self){
    
    try{
      
      self.pwa.promptEvent.prompt(); 
    
    }catch(e){

      self.app.info("Erro: "+e.message);

    }

  }
  /**
   * 
   * Confirm
   * 
   */
  _confirm(){

    this.app.confirm("Deseja instalar o app no seu dispositivo?",this.install,this);
      
  }
  /**
   * 
   * On Events
   * 
   */
  onEvents(){

    this.app.emitChangeUsuario.subscribe(data => {
      this.usuario = data; 
    });
    
  }
  /***
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(): void {
    this.onEvents();
  }

}
