<div class="dm-modal projeto-modal">
    <div class="dm-modal-title"></div>
    <div class="dm-modal-content"> 
        <iframe [src]="link" width="100%" height="100%"></iframe>
        <div class="dm-modal-footer">
            <button type="button" (click)="close()" class="btn-two radius">
                Fechar
            </button> 
            <div class="overlay"></div>   
        </div>    
    </div>    
</div>