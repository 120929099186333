import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService { 

  
  constructor() { }

  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonthString(value,min=false){

    let data  = new Date(value);
    let month = data.getMonth()+1;
    let out   = "";

    switch(month){
      case 1:
        out = min ? "JAN" : "Janeiro";
      break;
      case 2:
        out =  min ? "FEV" : "Fevereiro";
      break;
      case 3:
        out =  min ? "MAR" : "Março";
      break;
      case 4:
        out =  min ? "ABR" : "Abril";
      break;
      case 5:
        out =  min ? "MAI" : "Maio";
      break;
      case 6:
        out =  min ? "JUN" : "junho";
      break;
      case 7:
        out =  min ? "JUL" : "julho";
      break;
      case 8:
        out = min ? "AGO" : "Agosto";
      break;
      case 9:
        out =  min ? "SET" : "Setembro";
      break;
      case 10:
        out =  min ? "OUT" : "Outubro";
      break;
      case 11:
        out =  min ? "NOV" : "Novembro";
      break;
      case 12:
        out =  min ? "DEZ" : "Dezembro"; 
      break;
    }

    return out;

  }
  /**
   * 
   * Retorna o dia da data
   * 
   */
  getDay(value){

    let data = new Date(value);
    
    return data.getUTCDate() < 10 ? "0"+data.getUTCDate() : data.getUTCDate();

  }
  /**
   * 
   * Retorna o ano da data
   * 
   */
  getYear(value){
    
    let data = new Date(value);
        
    return data.getFullYear();
    
  }
  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonth(value){
    
    let data  = new Date(value);
    let month = data.getMonth()+1;
    let out   = month < 10 ? "0"+month : month;
    
    return out;
    
  }
  /**
   * 
   * Formata a data
   * 
   * @param data 
   * 
   */
  formatDateFromDatepicker(data){

    let date = new Date(data);

    let dia     = date.getDate();
    let mes:any = date.getUTCMonth()+1;
    mes         = mes < 10 ? "0"+mes : mes;
    let ano     = date.getFullYear();

    return ano+"-"+mes+"-"+dia;

  }
  /**
   * 
   * Retorna o mês em extenso
   * 
   */
  getMonthLabel(value,min=false){

    let out   = "";

    switch(value){
      case 1:
        out = min ? "JAN" : "Janeiro";
      break;
      case 2:
        out =  min ? "FEV" : "Fevereiro";
      break;
      case 3:
        out =  min ? "MAR" : "Março";
      break;
      case 4:
        out =  min ? "ABR" : "Abril";
      break;
      case 5:
        out =  min ? "MAI" : "Maio";
      break;
      case 6:
        out =  min ? "JUN" : "junho";
      break;
      case 7:
        out =  min ? "JUL" : "julho";
      break;
      case 8:
        out = min ? "AGO" : "Agosto";
      break;
      case 9:
        out =  min ? "SET" : "Setembro";
      break;
      case 10:
        out =  min ? "OUT" : "Outubro";
      break;
      case 11:
        out =  min ? "NOV" : "Novembro";
      break;
      case 12:
        out =  min ? "DEZ" : "Dezembro"; 
      break;
    }

    return out;

  }
  /**
   * 
   * Formatar data estadia
   * 
   */
  formatarDataEstadia(data){

    let date = data.split("/");

    let dia = date[0];
    let mes = this.getMonthLabel(parseInt(date[1]),true);
    let ano = date[2];

    return {
      dia: dia,
      mes: mes,
      ano: ano
    }

  }

}
