<div class="modulo modulo-managetour">
    <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <div class="container">
            <h2>{{data.titulo}}</h2> 
            <h3 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
        </div>    
    </div>
    <div class="modulo-content"> 
        <div class="container">
            <div class="logo"> 
                <figure>  
                    <img src="/images/managetour/logo.png" alt="ManageTour - Especialista em Turismo Receptivo" />
                </figure>    
            </div> 
            <div class="itens">
                <div class="row">
                    <div class="col-md-4" *ngFor="let i of itens">
                        <div class="item">
                            <div class="item-body">
                                <div class="foto">
                                    <img [src]="i.foto" [alt]="i.titulo" class="img-fluid" />
                                </div>   
                                <h4>{{i.titulo}}</h4>
                                <a [href]="i.link" target="_blank" [title]="i.titulo"> 
                                    SAIBA MAIS
                                </a>
                            </div>    
                            <div class="overlay"></div>    
                        </div>    
                    </div>    
                </div>    
            </div>     
        </div>
    </div>    
</div>    
