import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'file-estada',
  templateUrl: './file-estada.component.html',
  styleUrls: ['./file-estada.component.scss']
})
export class FileEstadaComponent implements OnInit {

  @Input("data") data:any = {};
  public usuario:any      = {};
  public dataIn:any = {
    dia: "",
    mes: "",
    ano: ""
  }
  public dataOut:any = {
    dia: "",
    mes: "",
    ano: ""
  }

  constructor(
    public _data: DataService,
    public date: DateService
  ) { }

  /**
   * 
   * Set Datas
   * 
   */
  setDatas(){

    this.dataIn  = this.date.formatarDataEstadia(this.data.dataInicioServicos);
    this.dataOut = this.date.formatarDataEstadia(this.data.dataFimServicos);
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(): void {
    this.usuario = this._data.getUsuario();
    this.setDatas();
  }

}
