<partial-header titulo="Meus Serviços"></partial-header>
<div class="page"> 
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="dm-card">
                    <div class="dm-card-content">
                        <usuario-info template="two"></usuario-info>
                    </div>    
                </div>    
            </div>    
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="dm-card">
                    <div class="dm-card-title"> 
                        <span>PERÍODO DA SUA ESTADIA</span>
                    </div>
                    <div class="dm-card-content">    
                        <file-estada [data]="dadosReserva"></file-estada>
                    </div>    
                </div>    
            </div>    
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="dm-card">
                    <div class="dm-card-title"> 
                        <span>MINHA PROGRAMAÇÃO</span>
                    </div>
                    <div class="dm-card-content">
                        <file-programacao [data]="dadosReserva"></file-programacao>
                    </div>    
                </div>    
            </div>    
        </div>
    </div>      
</div> 