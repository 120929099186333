<div class="dm-modal">
    <div class="dm-modal-titulo">
        <span>{{servico.servico}}</span>
        <div class="dm-modal-close" [style.background]="usuario.cor_secundaria" (click)="_close()">
            <span>x</span>
        </div>      
    </div>
    <div class="dm-modal-content">
        <div class="dm-card">
            <div class="dm-card-title">
                <span>DADOS DO SERVIÇO</span>
            </div>
            <div class="dm-card-content">
                <div class="item">
                    <div class="bloco">
                        <strong [style.color]="usuario.cor_secundaria">Data: </strong>
                    </div> 
                    <div class="bloco">
                        <span>{{servico.dataServico}} {{servico.horaServico}}</span>
                    </div>    
                </div> 
                <div class="item">
                    <div class="bloco">
                        <strong [style.color]="usuario.cor_secundaria">Hotel / Pousada: </strong>
                    </div> 
                    <div class="bloco">
                        <span>{{servico.hotelPousada}}</span>
                    </div>    
                </div> 
                <div class="item">
                    <div class="bloco">
                        <strong [style.color]="usuario.cor_secundaria">Categoria: </strong>
                    </div> 
                    <div class="bloco">
                        <span>{{servico.categoriaServico}}</span>
                    </div>    
                </div> 
                <div class="item">
                    <div class="bloco">
                        <strong [style.color]="usuario.cor_secundaria">Tipo: </strong>
                    </div> 
                    <div class="bloco">
                        <span>{{servico.tipoServico}}</span>
                    </div>    
                </div>    
            </div>    
        </div>
        <div class="dm-card" *ngIf="servico.aeroporto != null">
            <div class="dm-card-title">
                <span>DADOS DO VOO</span>
            </div>
            <div class="dm-card-content">
                <div class="item">
                    <div class="bloco">
                        <strong [style.color]="usuario.cor_secundaria">Aeroporto: </strong>
                    </div> 
                    <div class="bloco">
                        <span>{{servico.aeroporto}}</span> 
                    </div>    
                </div>
                <div class="item">
                    <div class="bloco">
                        <strong [style.color]="usuario.cor_secundaria">Voo: </strong>
                    </div> 
                    <div class="bloco">
                        <span>{{servico.voo}}</span> 
                    </div>    
                </div> 
            </div>
        </div>
        <div class="dm-card">
            <div class="dm-card-title">
                <span>PASSAGEIROS</span>
            </div> 
            <div class="dm-card-content">
                <div class="passageiro" *ngFor="let p of servico.paxsServico">
                    <strong [style.color]="usuario.cor_secundaria">Nome: </strong><span class="nome">{{p.nomePax}}</span>
                </div>    
            </div>    
        </div>    
    </div>     
</div>     
