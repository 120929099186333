<div class="modulo modulo-cases">
    <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <div class="container"> 
            <h2>{{data.titulo}}</h2>     
        </div>     
    </div>    
    <div class="modulo-content">
        <div class="container">
            <div class="cases">
                <div class="row">
                    <div class="col-lg-3 col-md-6" *ngFor="let c of data.cases">
                        <div class="item" (click)="_onClick(c)"> 
                            <div class="capa">
                                <figure> 
                                    <img defaultImage="/images/pixel.png" [lazyLoad]="c.logo" [alt]="c.nome" />
                                </figure> 
                            </div>
                            <h3>{{c.nome}}</h3>
                            <h4>{{c.info}}</h4>  
                        </div>    
                    </div>    
                </div>  
            </div>
            <div class="ver-todos">     
                <a routerLink="/projetos" title="Ver todos os cases - Digital Movement - Soluções em Tecnologia">
                    VER TODOS
                </a>     
            </div>    
        </div>
    </div>    
</div>    
