import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'sidebar-menu-button',
  templateUrl: './sidebar-menu-button.component.html',
  styleUrls: ['./sidebar-menu-button.component.scss']
})
export class SidebarMenuButtonComponent implements OnInit {

  constructor(
    private menu: MenuService
  ) { }

  /***
   * 
   * Click to open menu
   * 
   */
  _click(){

    this.menu.menuToggleOpen.emit();

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit():void{}

}
