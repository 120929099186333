import { Injectable } from '@angular/core';
import { SwUpdate, SwPush } from '@angular/service-worker';

declare var askUserToUpdate:any;
declare var window:any;

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  public promptEvent        = null;
  readonly VAPID_PUBLIC_KEY = "ELFsrnzMGqZXBKq2nJrruuASHdE2uwKudXepgb4tTt89Nt83Ag3_rjBEoMS9AJlxWsag6k8zQrRIybN5n3FPrg";

  constructor(
    private swUpdate: SwUpdate,
    private push: SwPush
  ){
    
    try{

      window.addEventListener('beforeinstallprompt',e => {
        
        e.preventDefault();
        this.promptEvent = e; 
        console.log(this.promptEvent)
      
      },(data) => {
        
      });

      window.addEventListener('appinstalled', (evt) => {
        this.promptEvent = null;
      });

      this.swUpdate.available.subscribe (evento => { 
        if (askUserToUpdate ()) { 
          window.location.reload(); 
        } 
      }); 

    }catch(e){
      
    }


  } 

  /***
   * 
   * Push notification
   * 
   */
  pushMessage(){




  }


  

}
