import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MatDialog } from '@angular/material/dialog';
import { FileServicoModal } from '../file-servico-modal/file-servico-modal.component';

@Component({
  selector: 'file-programacao',
  templateUrl: './file-programacao.component.html',
  styleUrls: ['./file-programacao.component.scss']
})
export class FileProgramacaoComponent implements OnInit {

  @Input("data") data:any = {};
  public dados            = {};
  public passageiros      = [];
  public servicos         = [];
  public usuario:any      = {};
  public total:any        = "00";

  constructor(
    private _data: DataService,
    private dialog: MatDialog
  ) { }

  /***
   * 
   * Set Data
   * 
   */
  setData(){

    this.dados = {
      cliente: this.data.cliente,
      titular: this.data.paxTitular,
      file: this.data.idFile
    }
    this.passageiros = typeof(this.data.paxsFile.length) == "undefined" ? [this.data.paxsFile] : this.data.paxsFile;
    this.servicos    = typeof(this.data.servicosFile.length) == "undefined" ? [this.data.servicosFile] : this.data.servicosFile;
 
    this.total = this.servicos.length > 10 ? this.servicos.length : "0"+this.servicos.length; 

  }
  /**
   * 
   * Usuário
   * 
   */
  setUsuario(){

    this.usuario = this._data.getUsuario();

  }
  /**
   * 
   * Open
   * 
   */
  _open(servico){

    this.dialog.open(FileServicoModal,{
      width: "700px",
      height: "auto",
      disableClose: true, 
      data: {  
        servico: servico   
      }
    });

  }
  /**
   * 
   * Inicialza as Funções
   * 
   * 
   */
  ngOnInit():void{
    this.setData();
    this.setUsuario();
  }

}
