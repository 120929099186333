<div class="modulo fale-conosco">
    <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
        <h3>
            {{data.titulo}}
        </h3>
    </div>
    <div class="modulo-content">
        <div class="content-form">
            <form class="form" (submit)="enviar()">
                <div class="row">
                    <div class="col-lg-6 col-md-12 input-icon">
                        <i class="fa fa-user" aria-hidden="true"></i>
                        <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Nome*" />
                    </div> 
                    <div class="col-lg-6 col-md-12 input-icon"> 
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                        <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="E-mail*" />
                    </div>   
                </div> 
                <div class="row"> 
                    <div class="col-md-12"> 
                        <textarea [(ngModel)]="dataItem.mensagem" name="mensagem" placeholder="Escreva uma mensagem..." rows="4"></textarea>
                    </div>   
                </div> 
                <div class="row">
                    <div class="col-md-12">
                        <button type="submit" class="btn-one">
                            ENVIAR
                        </button>    
                    </div>    
                </div>  
                <div class="linha-01"></div>  
                <div class="linha-02"></div>  
            </form>    
        </div>    
    </div>    
</div>
<loader [hidden]="!loader"></loader>

