import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nomeLabel'
})
export class NomeLabelPipe implements PipeTransform {

  transform(value:any, ...args: unknown[]){
    
    let nome = value.split(" ");

    if(nome.length > 0){ 
      return nome[0][0]+nome[nome.length-1][0];
    }else{
      return nome[0][0]+nome[0][1];
    }

  }

}
